import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { FormInput } from '@ts/components/molecules/AuthFormInput';

import { RouteChildrenProps } from 'react-router-dom';
import { UnauthenticatedFormContainer } from '../templates/UnauthenticatedFormContainer/UnauthenticatedFormContainer';
import { FormContainer } from '../atoms/containers';
import { PrimaryButton } from '../atoms/buttons';
import { FormLogo } from '../atoms/images';
import { VerticalGap } from '../atoms/spacing';
import { PasswordStrength } from '../molecules/PasswordStrength';
import { useCreatePasswordSubmissionMutation } from '@src/ts/services/api';
import usePasswordRules from '@ts/hooks/usePasswordRules';
import useOnEnterKeyUp from '@ts/hooks/useOnEnterKeyUp';
import { SessionManagementAlert } from '../organisms/SessionManagementAlert';
import { SessionManagementFeedback } from '../organisms/SessionManagementFeedback';
import LocaleSelector from '../organisms/LocaleSelector';
import { H2, H4 } from '../atoms/typography';
import { useAuth } from '@src/selectors/auth';
import { useDispatch } from 'react-redux';
import { logout as reducerLogout } from '@src/actions/auth';
import { logout } from '@src/utils/OneVueAPI';

export const CreatePasswordPage = ({
  history,
  match
}: RouteChildrenProps<{ token: string }>) => {
  const [password, setPassword] = useState('');

  const { authToken } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authToken) {
      dispatch(reducerLogout(authToken));
      logout(true);
    }
  }, [authToken, dispatch]);

  const [
    submitCreatePassword,
    { isError: error, isLoading: loading, isSuccess: success, reset: clear }
  ] = useCreatePasswordSubmissionMutation();

  const {
    screenAndPagesContentConfiguration: {
      passwordMgtContentConfiguration: config
    } = {},
    mainLogo,
    companyName
  } = useTheme();

  const handleDidClickSavePassword = useCallback(() => {
    submitCreatePassword({ password, token: `${match?.params?.token}` });
  }, [password, match?.params?.token, submitCreatePassword]);

  const handleGoToPortal = useCallback(() => {
    history?.replace('/login');
    clear();
  }, [clear, history]);

  const handleChangePassword = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    []
  );

  const { rules, validated } = usePasswordRules(password);

  const { handleKeyUp } = useOnEnterKeyUp(
    handleDidClickSavePassword,
    validated
  );

  return (
    <UnauthenticatedFormContainer>
      <LocaleSelector />
      <SessionManagementAlert
        message={
          config?.createPasswordFailureMessage ||
          'Something went wrong creating your password. Please try again later.'
        }
        dismiss={clear}
        display={error && !success}
      />
      {!success && <FormLogo alt={mainLogo.alt} src={mainLogo.src} />}
      <FormContainer>
        {success ? (
          <SessionManagementFeedback
            image={config?.createPasswordImage}
            fallbackIconSrc="/portal/lockCheck.svg"
            message={
              config?.createPasswordSuccessMessage ||
              'You successfully created your password!\nYou are now signed in.'
            }
          />
        ) : (
          <>
            <H2 $align="center" $textTransform="none">
              {config?.createPasswordTitle || 'Create password'}
            </H2>
            <H4 $fontWeight={400} $align="center" $margin="8px 0 16px 0">
              {config?.createPasswordSubtitle ||
                `Create a password to complete the registration process for ${companyName}.\nYou’ll use this password to log into your portal.`}
            </H4>
            <VerticalGap $gap={24} />
            <FormInput
              maxLength={64}
              label={config?.createPasswordPasswordField?.label || 'Password'}
              showLabel={config?.createPasswordPasswordField?.showLabel}
              hideLabel={config?.createPasswordPasswordField?.hideLabel}
              inputType="password"
              inputID="password"
              data-testid="password-input"
              value={password}
              placeholder={
                config?.createPasswordPasswordField?.placeholder || ''
              }
              required={true}
              onChange={handleChangePassword}
              onKeyUp={handleKeyUp}
            />
            {password && (
              <>
                <VerticalGap $gap={16} />
                <PasswordStrength password={password} rules={rules} />
              </>
            )}
          </>
        )}
      </FormContainer>
      <VerticalGap $gap={40} />
      {success ? (
        <PrimaryButton
          data-testid="password-saved-proceed-btn"
          onClick={handleGoToPortal}
          type="button"
          $minWidth="160px"
        >
          {config?.createPasswordSuccessButtonLabel || 'Go to Portal'}
        </PrimaryButton>
      ) : (
        <PrimaryButton
          data-testid="create-password-submit-btn"
          onClick={handleDidClickSavePassword}
          type="button"
          disabled={!validated}
          $minWidth="160px"
        >
          {loading ? (
            <i className="fas fa-circle-notch fa-spin" />
          ) : (
            config?.createPasswordButtonLabel || 'Save password'
          )}
        </PrimaryButton>
      )}
    </UnauthenticatedFormContainer>
  );
};
