import * as types from '../constants/actionTypes';
import StateLoader from './StateLoader';
import {
  storeUserProgram,
  setAccessToken,
  storeCookie,
  removeCookie,
  removeUserProgram,
  clearAccessToken,
  removeUserPRNOption,
  storeUserPRNOption
} from '../utils/OneVueAPI';
import { createBrowserHistory as createHistory } from 'history';
let history = createHistory();
export default function authReducer(
  state = new StateLoader().loadState().auth,
  action
) {
  switch (action.type) {
    case types.LOGIN_START:
      return {
        ...state,
        authToken: '',
        isLoading: true
      };
    case types.LOGIN_SUCCESS: {
      const profileString = action.response.user.id ? action.response : null;

      const authToken = action.response.sessionKey;
      storeUserProgram(profileString);
      storeUserPRNOption(profileString);
      setAccessToken(authToken);
      storeCookie(authToken);
      return {
        ...state,
        authToken,
        profileSession: profileString || state.profileSession,
        isLoading: false
      };
    }
    case types.FETCH_USER_START: {
      return {
        ...state,
        isProfileLoading: true
      };
    }
    case types.FETCH_USER_FAILURE: {
      return {
        ...state,
        isProfileLoading: false
      };
    }
    case types.SET_PROFILE: {
      return {
        ...state,
        profileSession: action.profile,
        isProfileLoading: false
      };
    }
    case types.LOGIN_FAILURE:
      return {
        ...state,
        authToken: '',
        isLoading: false
      };
    case types.LOGOUT_SUCCESS:
    case types.LOGOUT_FAILURE: {
      history.push('/login');
      removeUserProgram();
      removeUserPRNOption();
      clearAccessToken();
      localStorage.removeItem('iPEP:state');
      removeCookie();
      return {
        ...state,
        loggedOutFromIdle: action.meta?.fromIdle,
        authToken: '',
        profileSession: {},
        isLoading: false
      };
    }
    default:
      return state;
  }
}
