import { get, isEmpty } from 'lodash';

import config from '../config/config';
import request from './request.js';
import { getNeedsToSign } from './consents';
import {
  getUserLocale,
  getUserStudyFromProgram,
  setUserLocale
} from './cms.js';
import { PRE_SCREENING_PASSED } from '../constants/screeningPassedTypes.js';

const { BASE_URL, EHR_DATA_BASE_URL } = config;

const ACCESS_TOKEN_KEY = 'access_token';
const USER_PROGRAM = 'user_program';
const USER_PRN_OPTION = 'user_prn_option';
const ACTIVITY_TS = 'activity_timestamp';

function storeCookie(sessionKey) {
  var localhostUrl = 'http://localhost';
  var cookieStr = 'session=' + sessionKey + ';max-age=1800;Path=/;';
  document.cookie = BASE_URL.startsWith(localhostUrl)
    ? cookieStr
    : cookieStr + ';secure';
}

function removeCookie() {
  var cookies = document.cookie.split(';');

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

function storeReferral(referral) {
  window.sessionStorage.setItem('referral', referral);
}

function getReferral() {
  return window.sessionStorage.getItem('referral');
}

function login(email, password, completion, resetSession) {
  var json = JSON.stringify({ email: email, password: password });
  fetch(BASE_URL + '/login', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: json
  })
    .then(function(response) {
      return response.json();
    })
    .catch(error => console.error('Error:', error))
    .then(function(responseJSON) {
      if (responseJSON && responseJSON.success !== false) {
        if (responseJSON.sessionKey) {
          let token = responseJSON.sessionKey;
          setAccessToken(token);
          storeCookie(token);
          if (resetSession) {
            storeUserProgram(responseJSON);
            storeUserPRNOption(responseJSON);
          }
          completion(responseJSON);
        }
      } else {
        completion(responseJSON);
      }
    });
}

function validateCredentials(email, password) {
  const accessToken = getAccessToken();
  return request({
    url: 'validateCredentials',
    method: 'POST',
    data: { email, password },
    headers: {
      'Session-Key': accessToken
    }
  });
}

async function forgotPassword(email) {
  return request({
    url: 'password',
    method: 'POST',
    data: { email }
  });
}

function resetPassword(formData) {
  return request({
    url: 'password',
    method: 'POST',
    data: formData
  });
}

function changePassword(formObject, completion) {
  let accessToken = getAccessToken();

  if (accessToken) {
    var json = JSON.stringify(formObject);
    fetch(BASE_URL + '/update/password', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Session-Key': accessToken
      },
      body: json
    })
      .then(function(response) {
        return response.json();
      })
      .catch(error => console.error('Error', error))
      .then(function(responseJSON) {
        if (responseJSON === undefined) {
          const errorJSON = {
            success: false,
            error: 'There was a network error.'
          };
          completion(errorJSON);
        } else {
          completion(responseJSON);
        }
      });
  }
}

async function register(formObject) {
  const response = await request({
    url: 'v2/register',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    data: formObject
  });
  const json = response.data;
  return json;
}

function validateScreening(data) {
  const accessToken = getAccessToken();
  return request({
    url: 'screeningValidate',
    method: 'POST',
    headers: { 'Session-Key': accessToken },
    data
  });
}

function messagingPreference(token, completion) {
  var json = JSON.stringify({ token: '' + token });
  fetch(BASE_URL + '/messaging/preferences', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Session-Key': ''
    },
    body: json
  })
    .then(function(response) {
      return response.json();
    })
    .catch(error => console.error('Error test', error))
    .then(function(responseJSON) {
      completion(responseJSON);
    });
}

function confirmToken(token) {
  return request({
    url: 'token',
    method: 'POST',
    data: { token }
  });
}

function getInboxItems(completion) {
  let accessToken = getAccessToken();
  if (accessToken) {
    fetch(BASE_URL + '/inbox', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Session-Key': accessToken
      }
    })
      .then(function(response) {
        return response.json();
      })
      .catch(error => console.error('Error', error))
      .then(function(responseJSON) {
        completion(responseJSON);
      });
  }
}

function updateInboxItem(id, status, completion) {
  let accessToken = getAccessToken();
  var json = JSON.stringify({ status: status });
  fetch(BASE_URL + '/inbox/' + id, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Session-Key': accessToken
    },
    body: json
  })
    .then(function(response) {
      return response.json();
    })
    .catch(error => console.error('Error test', error))
    .then(function(responseJSON) {
      completion(responseJSON);
    });
}

function data(formObject, type, completion, source) {
  let accessToken = getAccessToken();
  var json = JSON.stringify({
    status: '200',
    userData: formObject,
    provider: source
  });
  fetch(BASE_URL + '/data', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Session-Key': accessToken
    },
    body: json
  })
    .then(function(response) {
      return response.json();
    })
    .catch(error => console.error('Error test', error))
    .then(function(responseJSON) {
      completion(responseJSON);
    });
}

function startGreenlightWorkflow(completion) {
  const accessToken = getAccessToken();
  request({
    url: 'ehr/greenlight',
    baseURL: EHR_DATA_BASE_URL,
    method: 'POST',
    headers: {
      'Session-Key': accessToken
    }
  })
    .catch(error => console.error(error))
    .then(({ data } = {}) => completion(data));
}

function getMFConnectParams(completion, userId) {
  var json = JSON.stringify({ userId });
  let accessToken = getAccessToken();
  if (accessToken) {
    fetch(BASE_URL + '/medfusion', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Session-Key': accessToken
      },
      body: json
    })
      .then(function(response) {
        return response.json();
      })
      .catch(error => console.error('Error', error))
      .then(function(responseJSON) {
        completion(responseJSON);
      });
  }
}

function getSHRStatus(completion) {
  let accessToken = getAccessToken();
  if (accessToken) {
    fetch(BASE_URL + '/shr/status', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Session-Key': accessToken
      }
    })
      .then(function(response) {
        return response.json();
      })
      .catch(error => console.error('Error', error))
      .then(function(responseJSON) {
        completion(responseJSON);
      });
  }
}

// To-Do "Get Profile" does not return if the user has confirmed their email address
// The new Scala API must return this confirmed: true/false so that we know whether to prompt
// the user to confirm their email address
async function getProfile(accessToken) {
  if (accessToken) {
    try {
      const response = await fetch(BASE_URL + '/user', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Session-Key': accessToken
        }
      });
      const responseJSON = await response.json();
      return responseJSON;
    } catch (error) {
      console.error('Error', error);
    }
  }
}

function updateProfile(formObject, completion) {
  let accessToken = getAccessToken();

  if (accessToken) {
    var json = JSON.stringify(formObject);
    fetch(BASE_URL + '/updateProfile', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Session-Key': accessToken
      },
      body: json
    })
      .then(function(response) {
        return response.json();
      })
      .catch(error => console.error('Error', error))
      .then(function(responseJSON) {
        if (responseJSON === undefined) {
          const errorJSON = {
            success: false,
            error: 'There was a network error.'
          };
          completion(errorJSON);
        } else {
          completion(responseJSON);
        }
      });
  }
}

export const getUserConsents = async () => {
  try {
    const accessToken = getAccessToken();
    if (accessToken) {
      const response = await request({
        url: 'consents',
        headers: {
          'Session-Key': accessToken
        }
      });
      return response.data;
    }
  } catch (err) {
    console.warn(`Cannot retrieve user consents: ${err.message}`);
    return { consents: [] };
  }
};

async function getConsent(id) {
  const accessToken = getAccessToken();

  return request({
    url: `consent/${id}`,
    headers: {
      'Session-Key': accessToken
    }
  });
}

export const signConsent = async (signature, consent) => {
  if (!consent) return;
  try {
    const { id: contentfulId, icfVersionNumber } = consent;
    const accessToken = getAccessToken();
    const locale = getUserLocale();
    const data = {
      signature,
      contentfulId,
      locale,
      version: `${icfVersionNumber}`
    };
    const response = await request({
      url: 'consent',
      method: 'POST',
      headers: {
        'Session-Key': accessToken
      },
      data
    });
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

function addConsent(formObject, completion) {
  let accessToken = getAccessToken();
  var json = JSON.stringify(formObject);
  if (accessToken) {
    fetch(BASE_URL + '/addConsent', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Session-Key': accessToken
      },
      body: json
    })
      .then(function(response) {
        return response.json();
      })
      .catch(error => console.error('Error', error))
      .then(function(responseJSON) {
        completion(responseJSON);
      });
  }
}

async function redirectUserAfterProfile(props) {
  const {
    authToken,
    history,
    profileSession: {
      user: { program, prnOption }
    }
  } = props;
  const responseJSON = await getProfile(authToken);
  if (responseJSON?.success) {
    const { consents } = await getUserConsents();
    const userStudy = await getUserStudyFromProgram(program, prnOption);
    const consent = get(userStudy, '0.fields.consent.fields');
    const needsToSign = getNeedsToSign(consent, consents);
    const needsScreening =
      responseJSON.user.screeningPassed === PRE_SCREENING_PASSED;
    if (needsToSign || needsScreening) {
      history.push('/home/onboarding');
    } else {
      history.push('/home');
    }
  }
}
function redirectUser(props, isLoggedIn) {
  const { profileSession: session } = props;
  if (isLoggedIn && session?.confirmed) {
    if (isEmpty(session.user.permissions)) {
      redirectUserAfterProfile(props);
    } else {
      window.location.replace('/portal');
    }
  } else {
    logout();
  }
}

async function logout(skipRelocation) {
  removeUserProgram();
  removeUserPRNOption();
  clearAccessToken();
  removeCookie();
  if (!skipRelocation) {
    window.location = '/';
  }
}

function requireAuth(nextState, replace) {
  if (!isLoggedIn()) {
    replace({ pathname: '/' });
  }
}

function setAccessToken(token) {
  let accessToken = token;
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(ACTIVITY_TS, Date.now());
}

function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

function storeUserProgram(profile) {
  if (profile.success === true && profile.user.id && profile.user.program) {
    localStorage.setItem(USER_PROGRAM, profile.user.program);
  }
}

function removeUserProgram() {
  localStorage.removeItem(USER_PROGRAM);
}

function getUserProgram() {
  return localStorage.getItem(USER_PROGRAM);
}

function storeUserPRNOption(profile) {
  if (profile.success === true && profile.user.id) {
    localStorage.setItem(USER_PRN_OPTION, profile.user.prnOption);
  }
}

function removeUserPRNOption() {
  localStorage.removeItem(USER_PRN_OPTION);
}

function getUserPRNOption() {
  return localStorage.getItem(USER_PRN_OPTION);
}

function isLoggedIn() {
  let activityTS = window.localStorage.getItem(ACTIVITY_TS);
  if (activityTS) {
    if (Date.now() - activityTS > 1800000) {
      clearAccessToken();
      redirectUser();
    } else {
      const accessToken = getAccessToken();
      return !!accessToken;
    }
  } else {
    const accessToken = getAccessToken();
    return !!accessToken;
  }
}

function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  const locale = getUserLocale();
  localStorage.clear();
  setUserLocale(locale);
}

export {
  login,
  logout,
  validateCredentials,
  forgotPassword,
  resetPassword,
  changePassword,
  messagingPreference,
  confirmToken,
  data,
  requireAuth,
  storeCookie,
  storeReferral,
  getReferral,
  setAccessToken,
  getAccessToken,
  getInboxItems,
  updateInboxItem,
  isLoggedIn,
  register,
  validateScreening,
  redirectUser,
  startGreenlightWorkflow,
  getMFConnectParams,
  getSHRStatus,
  removeCookie,
  getConsent,
  addConsent,
  getProfile,
  updateProfile,
  clearAccessToken,
  getUserProgram,
  storeUserProgram,
  removeUserProgram,
  getUserPRNOption,
  storeUserPRNOption,
  removeUserPRNOption
};
