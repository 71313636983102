import { RawDataType } from '@ts/models';
import { ColumnConfig } from 'theme';
import i18n from '../../i18n';

const columnConfigs: Array<ColumnConfig<RawDataType>> = [
  { id: 'time', label: i18n.t('rawDataType.time'), sortable: true },
  { id: 'value', label: i18n.t('rawDataType.value'), sortable: true },
  { id: 'status', label: i18n.t('rawDataType.status'), sortable: true },
  { id: 'unit', label: i18n.t('rawDataType.unit'), sortable: true }
];

export default {
  columnConfigs,
  displayingRawDataTypeLabel: i18n.t('rawDataType.rawDataTypeLabel'),
  rawDataTypeLabel: i18n.t('rawDataType.rawDataType')
};
