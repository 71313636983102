import styled, { css } from 'styled-components';

export const DialogElement = styled.dialog`
  border: none;
  border-radius: 8px;
  padding: 0;
  overflow: visible;

  &:focus-visible {
    outline: none;
  }

  &::backdrop {
    background-color: ${({ theme }) => theme.colors.surface.overlay.dark};
    box-shadow: 0px 4px 4px 0px rgba(22, 28, 34, 0.08);
  }
`;

type DialogWrapperProp = {
  $width?: number;
};

export const DialogWrapper = styled.div<DialogWrapperProp>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ $width }) => ($width ? `width: ${$width}px;` : '')}
  max-height: calc(100vh - 308px);
`;

export const DialogOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

const commonHeaderFooterStyles = css`
  display: flex;
  padding: 16px 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: ${({ theme }) => theme.colors.surface.background.primary};
`;

export const DialogHeader = styled.div`
  ${commonHeaderFooterStyles}
  height: 72px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
`;

type DialogBodyProps = {
  $minHeight?: number;
  $gap?: string;
  $center?: boolean;
};
export const DialogBody = styled.div<DialogBodyProps>`
  ${commonHeaderFooterStyles}
  flex: 1;
  flex-direction: column;
  gap: ${({ $gap = '24px' }) => $gap};
  align-items: ${({ $center }) => ($center ? 'center' : 'flex-start')};
  justify-content: ${({ $center }) => ($center ? 'center' : 'space-between')};
  padding-bottom: 24px;
  ${({ $minHeight }) => ($minHeight ? `min-height: ${$minHeight}px;` : '')}

  overflow: auto;
`;

export const DialogBodyFormWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-self: stretch;
`;

type DialogFooterProps = { $mode: 'space-between' | 'flex-end' };
export const DialogFooter = styled.div<DialogFooterProps>`
  ${commonHeaderFooterStyles}

  justify-content: ${({ $mode }) => $mode};

  border-top: 1px solid ${({ theme }) => theme.colors.border.primary};
  box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const DialogFooterActions = styled.div`
  display: flex;
  gap: 16px;
`;
