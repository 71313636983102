import i18n from '../../i18n';

export default {
  resetPasswordFailureModalTitle: i18n.t(
    'passwordMgtContent.resetPasswordFailureModalTitle'
  ),
  resetPasswordFailureModalSubmitBtnLabel: i18n.t(
    'passwordMgtContent.resetPasswordFailureModalSubmitBtnLabel'
  )
};
