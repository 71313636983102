import resolve from 'contentful-resolve-response';
import { startsWith } from 'lodash';
import request from './request';

const USER_LOCALE = 'userLocale';

function getLanguage() {
  return (
    navigator.languages?.[0] ||
    navigator.language ||
    navigator.browserLanguage ||
    navigator.userLanguage
  );
}

function getDefaultCMSLocale(locales) {
  return locales.find(locale => locale.default);
}

function getLocaleByCode(locales, localeCode) {
  return locales.find(({ code }) => code === localeCode);
}

function getLocaleByLanguage(locales, lang) {
  return locales.find(({ code }) => startsWith(code, lang));
}

export function getUserLocale() {
  return localStorage.getItem(USER_LOCALE);
}

export function setUserLocale(locale) {
  return localStorage.setItem(USER_LOCALE, locale);
}

class CMSClient {
  locales = [];

  async _request(path, opts) {
    const response = await request({
      url: `cms/${path}`,
      queryParams: opts,
      excludeNoCacheHeaders: true
    });
    return response.data;
  }

  async getEntries(opts = {}) {
    const locale = await this.getAvailableLocaleForUser();
    return resolve(await this._request('entries', { locale, ...opts }));
  }

  getEntry(id, opts = {}) {
    return this._request(`entries/${id}`, opts);
  }

  async getLocales(opts = {}) {
    if (this.locales.length) return this.locales;
    this.locales = resolve(await this._request('locales', opts));
    return this.locales;
  }

  async getAvailableLocaleForUser() {
    const locales = await this.getLocales();
    const currentLocale = getUserLocale();
    if (currentLocale) {
      // validate stored locale string is in contentful
      const found = getLocaleByCode(locales, currentLocale);
      if (found) return currentLocale;
    }
    let locale = getLanguage() || 'en-US';
    let found = getLocaleByCode(locales, locale);
    if (!found) {
      const [language] = locale.split('-');
      found = getLocaleByLanguage(locales, language);
    }
    if (!found) found = getDefaultCMSLocale(locales);
    // this should not be possible
    if (!found) locale = 'en-US';
    return found?.code || locale;
  }
}

const client = new CMSClient();

const getEntries = (opts = {}) => client.getEntries(opts);

const getUserStudyFromProgram = (program, prnOption) => {
  if (program) {
    const [diseaseCode, programCode] = program.split('__');
    if (!diseaseCode || !programCode) {
      return Promise.reject(new Error('Program not specified'));
    }
    return getEntries({
      content_type: 'prnOption',
      'fields.disease.sys.contentType.sys.id': 'appDiseaseTypes',
      'fields.disease.fields.diseaseCode': diseaseCode,
      'fields.program.sys.contentType.sys.id': 'programType',
      'fields.program.fields.programId': programCode,
      include: 10
    });
  } else if (prnOption) {
    return getEntries({
      content_type: 'prnOption',
      'fields.prnOptionId': prnOption,
      include: 10
    });
  } else {
    return Promise.reject(new Error('Site not specified'));
  }
};

export { client, getEntries, getUserStudyFromProgram };
