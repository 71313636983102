import React, {
  useEffect,
  useState,
  createContext,
  useCallback,
  useContext,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import { get } from 'lodash';
import { ReactReduxContext } from 'react-redux';
import WebFont from 'webfontloader';

import {
  getEntries,
  getUserLocale,
  getUserStudyFromProgram
} from '../utils/cms';
import { flattenModel } from '../actions/utils';
import { getUserPRNOption, getUserProgram } from '../utils/OneVueAPI';
import { StyledThemeProvider } from '../ts/theme';
import studyContentConfiguration from '../ts/constants/studyContentConfiguration';
import localPasswordMgtContentConfiguration from '../ts/constants/passwordMgtContentConfiguration';
import auditContentConfiguration from '../ts/constants/auditContentConfiguration';
import professionalsContentConfiguration from '../ts/constants/professionalsContentConfiguration';
import profilePageContentConfiguration from '../ts/constants/profilePageContentConfiguration';
import participantProfilePageContentConfiguration from '../ts/constants/participantProfileContentConfiguration';
import participantsContentConfiguration from '../ts/constants/participantsContentConfiguration';
import rawDataTypeContentConfiguration from '../ts/constants/rawDataTypeContentConfiguration';

export function setFontsAndColors({
  primaryFont,
  secondaryFont,
  primaryColor: primaryColorText,
  secondaryColor: secondaryColorText
}) {
  const primaryColor = Color(primaryColorText);
  const primaryColorLight = primaryColor.fade(0.4);
  const secondaryColor = Color(secondaryColorText);
  const secondaryColorLight = Color(secondaryColor).fade(0.4);

  document.documentElement.style.setProperty('--color-primary', primaryColor);
  document.documentElement.style.setProperty(
    '--color-primary-light',
    primaryColorLight
  );

  document.documentElement.style.setProperty(
    '--color-secondary',
    secondaryColor
  );

  document.documentElement.style.setProperty(
    '--color-secondary-light',
    secondaryColorLight
  );

  WebFont.load({
    google: {
      families: [
        `${primaryFont}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900`
      ]
    },
    fontactive: function(font) {
      document.documentElement.style.setProperty('--font-primary', font);
    },
    fontinactive: function() {
      document.documentElement.style.setProperty(
        '--font-primary',
        'Fjalla One'
      );
    }
  });

  WebFont.load({
    google: {
      families: [
        `${secondaryFont}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900`
      ]
    },
    fontactive: function(font) {
      document.documentElement.style.setProperty('--font-secondary', font);
    },
    fontinactive: function() {
      document.documentElement.style.setProperty(
        '--font-secondary',
        'Overpass'
      );
    }
  });
  /** load portal font  */
  WebFont.load({
    google: {
      families: [
        `Nunito Sans:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900`
      ]
    }
  });
}

export const ThemeContext = createContext({
  referToken: null,
  theme: {
    mainLogo: {},
    smallLogo: {},
    liaisonPhoto: {},
    programNameLabel: null
  }
});

const sanitizeImage = ({
  fields: {
    description,
    file: {
      details: {
        image: { width, height }
      },
      url
    }
  }
}) => ({
  alt: description,
  src: url,
  width,
  height
});

export function otherContentConfiguration(locale) {
  // Temporary
  const i18n = {
    [locale]: {
      localPasswordMgtContentConfiguration,
      studyContentConfiguration,
      auditContentConfiguration,
      rawDataTypeContentConfiguration,
      professionalsContentConfiguration,
      profilePageContentConfiguration,
      participantProfilePageContentConfiguration,
      participantsContentConfiguration
    }
  };
  return i18n[locale];
}

export const ThemeProvider = ({ children, themeId = 'base' }) => {
  const [theme, setTheme] = useState({
    mainLogo: {},
    smallLogo: {},
    liaisonPhoto: {}
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [authKey, setAuthKey] = useState('');
  const searchParams = new URLSearchParams(location.search);
  const referToken = searchParams.get('inspire_id') || searchParams.get('r');

  const updateProgramName = useCallback(async (userProgram, prnOptionId) => {
    try {
      const [prnOption] = await getUserStudyFromProgram(
        userProgram,
        prnOptionId
      );

      const diseaseName = get(prnOption, [
        'fields',
        'disease',
        'fields',
        'diseaseName'
      ]);

      const programLabel = get(prnOption, [
        'fields',
        'program',
        'fields',
        'label'
      ]);

      const programName = `${diseaseName} ${programLabel}`;
      setTheme(theme => ({ ...theme, programName }));
    } catch (error) {
      /*eslint no-console: ["error", { allow: [ "error"] }] */
      console.error(error.stack);
    }
  }, []);

  const fetch = useCallback(
    async ({ useLoading } = { useLoading: false }) => {
      try {
        setError(null);
        useLoading && setLoading(true);
        const [{ fields }] = await getEntries({
          content_type: 'whiteLabeling',
          'fields.id': themeId,
          include: 10
        });
        const {
          liaisonPhoto,
          mainLogo,
          smallLogo,
          primaryColor,
          secondaryColor,
          landingPage,
          primaryFont,
          secondaryFont
        } = fields;

        setFontsAndColors({
          primaryColor,
          secondaryColor,
          primaryFont,
          secondaryFont
        });

        setTheme({
          ...fields,
          programNameLabel: fields.programName,
          mainLogo: sanitizeImage(mainLogo),
          smallLogo: sanitizeImage(smallLogo),
          liaisonPhoto: liaisonPhoto ? sanitizeImage(liaisonPhoto) : {},
          landingPage: landingPage.fields,
          prnOptionRoleSelectionScreen: flattenModel(
            fields.prnOptionRoleSelectionScreen
          ),
          screenAndPagesContentConfiguration: {
            ...flattenModel(fields.screenAndPagesContentConfiguration),
            ...otherContentConfiguration(getUserLocale())
          },
          policies: fields.policies?.map(flattenModel)
        });
        document.title = `${fields.programName} | ${fields.companyName}`;
        const userProgram = getUserProgram();
        const prnOption = getUserPRNOption();
        if (userProgram || prnOption) {
          updateProgramName(userProgram, prnOption);
        }
      } catch (err) {
        /*eslint no-console: ["error", { allow: [ "error"] }] */
        console.error(err.stack);
        setError(err);
      } finally {
        useLoading && setLoading(false);
      }
    },
    [themeId, updateProgramName]
  );

  useEffect(() => {
    fetch({ useLoading: true });
  }, [fetch]);

  const { store } = useContext(ReactReduxContext);

  const storeAuthKey = useMemo(
    () => get(store.getState(), ['auth', 'authToken']),
    [store]
  );

  const checkAuthKey = useCallback(() => {
    setAuthKey(storeAuthKey);
    if (authKey !== storeAuthKey) {
      const userProgram = get(
        store.getState(),
        ['auth', 'profileSession', 'user', 'program'],
        ''
      );
      const userPRNOption = get(
        store.getState(),
        ['auth', 'profileSession', 'user', 'prnOption'],
        ''
      );
      updateProgramName(userProgram, userPRNOption);
    }
  }, [updateProgramName, store, authKey, storeAuthKey]);

  useEffect(() => {
    const unsubscribe = store.subscribe(checkAuthKey);
    return unsubscribe;
  }, [store, checkAuthKey]);

  const themeValue = useMemo(
    () => ({
      updateProgramName,
      theme,
      referToken,
      refetchTheme: fetch
    }),
    [updateProgramName, theme, referToken, fetch]
  );

  return (
    <ThemeContext.Provider value={themeValue}>
      {loading || error ? (
        <div className="container">
          <div className="row justify-content-center vertical-center">
            <picture className="loading-logo-container">
              {loading ? (
                <img
                  className="loading-logo col-2"
                  src={theme.smallLogo?.src || '/signingin.gif'}
                  alt={theme.smallLogo?.alt}
                  style={{ objectFit: 'contain', height: 60 }}
                />
              ) : null}
              {error ? (
                <>
                  <img
                    className="animated infinite headShake loading-logo col-2"
                    src={theme.smallLogo?.src || '/LoadGif.gif'}
                    alt={theme.smallLogo?.alt}
                    style={{ objectFit: 'contain', height: 144 }}
                  />
                  <h3>An Error Ocurred</h3>
                  <h4>
                    {error.message} {error.data?.error}
                  </h4>
                  <button
                    className="btn btn-info"
                    onClick={() => fetch({ useLoading: true })}
                  >
                    Retry
                  </button>
                </>
              ) : null}
            </picture>
          </div>
        </div>
      ) : null}
      {!loading && !error ? (
        <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
      ) : null}
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.any,
  themeId: PropTypes.string
};

export const ThemeConsumer = ThemeContext.Consumer;
export const useTheme = () => useContext(ThemeContext);
