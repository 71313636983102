import React from 'react';
import PropTypes from 'prop-types';
import ResourceItem from './ResourceItem';
import ResourceSlider from '../Slider/ResourceSlider';

const ResourcesList = ({ isLoading, resources, featuredResources }) => (
  <div>
    {isLoading ? (
      <div id="loading-holder" className="mb-3" data-testid="resources-loading">
        <div className="row justify-content-center">
          <div className="fa-3x text-center">
            <i className="fas fa-circle-notch fa-spin" />
          </div>
        </div>
      </div>
    ) : null}
    <ResourceSlider resource={featuredResources} />
    {resources.map((entry, index) => (
      <ResourceItem entry={entry} key={index} />
    ))}
  </div>
);

ResourcesList.propTypes = {
  isLoading: PropTypes.bool,
  resources: PropTypes.any,
  featuredResources: PropTypes.any
};

export default ResourcesList;
