import moment from 'moment';
import { getUserLocale } from '@src/utils/cms';

export const getQueryDate = (date: Date | null) => {
  if (!date) return undefined;

  return moment(date).format('YYYY-MM-DD');
};

export function toLocaleDateString(date: Date) {
  const userLocale = getUserLocale();
  return date.toLocaleDateString(userLocale ?? navigator.language);
}
