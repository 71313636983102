import { get } from 'lodash';
import { getOr } from 'lodash/fp';
import { format } from 'date-fns';

import * as types from '../constants/actionTypes';
import request from '../utils/request';
import { getNeedsToSign } from '../utils/consents';
import { getUserStudyFromProgram } from '../utils/cms';
import { selectContentfulConsent } from '../selectors/user';
import { getUserConsents } from '../utils/OneVueAPI';

const getConsentStart = () => {
  return {
    type: types.FETCH_CONSENT_START
  };
};

const getConsentSuccess = response => {
  return {
    type: types.FETCH_CONSENT_SUCCESS,
    response
  };
};

const getConsentFailure = error => {
  return {
    type: types.FETCH_CONSENT_FAILURE,
    error
  };
};

const getContentfulConsentStart = () => {
  return {
    type: types.FETCH_CONTENTFUL_CONSENT_START
  };
};

const getContentfulConsentSuccess = response => {
  return {
    type: types.FETCH_CONTENTFUL_CONSENT_SUCCESS,
    response
  };
};

const getContentfulConsentFailure = error => {
  return {
    type: types.FETCH_CONTENTFUL_CONSENT_FAILURE,
    error
  };
};

const getConsentsStart = () => {
  return {
    type: types.FETCH_CONSENTS_START
  };
};

const getConsentsSuccess = response => {
  return {
    type: types.FETCH_CONSENTS_SUCCESS,
    response
  };
};

const getConsentsFailure = error => {
  return {
    type: types.FETCH_CONSENTS_FAILURE,
    error
  };
};
const getStatusStart = () => {
  return {
    type: types.FETCH_STATUS_START
  };
};

const getStatusSuccess = response => {
  return {
    type: types.FETCH_STATUS_SUCCESS,
    response
  };
};

const getStatusFailure = error => {
  return {
    type: types.FETCH_STATUS_FAILURE,
    error
  };
};
const getProfileStart = () => {
  return {
    type: types.FETCH_USER_START
  };
};

const getProfileSuccess = profile => {
  return {
    type: types.SET_PROFILE,
    profile
  };
};

const getProfileFailure = error => {
  return {
    type: types.FETCH_USER_FAILURE,
    error
  };
};
const loadInboxStart = () => {
  return {
    type: types.LOAD_INBOX_START
  };
};

const loadInboxSuccess = response => {
  return {
    type: types.LOAD_INBOX_SUCCESS,
    response
  };
};

const loadInboxFailure = error => {
  return {
    type: types.LOAD_INBOX_FAILURE,
    error
  };
};
const updateInboxItemStart = () => {
  return {
    type: types.UPDATE_INBOX_ITEM_START
  };
};

const updateInboxItemSuccess = response => {
  return {
    type: types.UPDATE_INBOX_ITEM_SUCCESS,
    response
  };
};

const updateInboxItemFailure = error => {
  return {
    type: types.UPDATE_INBOX_ITEM_FAILURE,
    error
  };
};
const getDoctorByIdStart = () => {
  return {
    type: types.FETCH_DOCTOR_BY_ID_START
  };
};

const getDoctorByIdSuccess = response => {
  return {
    type: types.FETCH_DOCTOR_BY_ID_SUCCESS,
    response
  };
};

const getDoctorByIdFailure = error => {
  return {
    type: types.FETCH_DOCTOR_BY_ID_FAILURE,
    error
  };
};
export const getProfile = accessToken => {
  return async dispatch => {
    try {
      dispatch(getProfileStart());
      const response = await request({
        url: 'user',
        headers: {
          'Session-Key': accessToken
        }
      });
      const json = response.data;
      dispatch(getProfileSuccess(json));
    } catch (err) {
      dispatch(getProfileFailure(err));
    }
  };
};
export const loadInbox = accessToken => {
  return async dispatch => {
    try {
      dispatch(loadInboxStart());
      const response = await request({
        url: 'inbox',
        headers: {
          'Session-Key': accessToken
        }
      });
      const json = response.data;
      dispatch(loadInboxSuccess(json));
    } catch (err) {
      dispatch(loadInboxFailure(err));
    }
  };
};

export const updateInboxItem = (accessToken, id, status) => {
  return async dispatch => {
    try {
      dispatch(updateInboxItemStart());

      const response = await request({
        url: `inbox/${id}`,
        method: 'PUT',
        headers: {
          'Session-Key': accessToken
        },
        data: { status }
      });
      const res = response.data;

      dispatch(updateInboxItemSuccess(res));
    } catch (err) {
      dispatch(updateInboxItemFailure(err));
    }
  };
};

const formatDate = d => format(new Date(d), 'DD MMM YYYY'); // 08 Apr 2020

const resolveConsent = consent => {
  const {
    fields,
    sys: { id, revision }
  } = consent;
  const {
    protocolVersionDate,
    irbApprovalDate,
    icfIrbApprovalDate,
    sections
  } = fields;
  const sectionFields = sections.map(({ fields }) => fields);
  const formattedProtocolVersionDate =
    protocolVersionDate && formatDate(protocolVersionDate);
  const formattedIrbApprovalDate =
    irbApprovalDate && formatDate(irbApprovalDate);
  const formattedIcfIrbApprovalDate =
    icfIrbApprovalDate && formatDate(icfIrbApprovalDate);
  return {
    ...fields,
    id,
    revision,
    protocolVersionDate: formattedProtocolVersionDate,
    irbApprovalDate: formattedIrbApprovalDate,
    icfIrbApprovalDate: formattedIcfIrbApprovalDate,
    sections: sectionFields
  };
};

export const getConsents = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(getConsentsStart());
      await dispatch(getContentfulConsent());
      const { consents } = await getUserConsents();

      const consent = selectContentfulConsent(getState());
      const needsToSign = getNeedsToSign(consent, consents);
      dispatch(
        getConsentsSuccess({
          consent,
          signedConsents: consents,
          needsToSign
        })
      );
    } catch (err) {
      dispatch(getConsentsFailure(get(err, 'response.data')));
    }
  };
};
export const getConsent = (accessToken, id) => {
  return async dispatch => {
    try {
      dispatch(getConsentStart());

      const response = await request({
        url: `consent/${id}`,
        headers: {
          'Session-Key': accessToken
        }
      });
      const json = response.data;

      const { consent } = json;

      dispatch(getConsentSuccess({ ...json, consent: JSON.parse(consent) }));
    } catch (err) {
      dispatch(getConsentFailure(err));
    }
  };
};

export const getContentfulConsent = () => async (dispatch, getState) => {
  try {
    dispatch(getContentfulConsentStart());
    const {
      auth: {
        profileSession: {
          user: { program, prnOption }
        }
      }
    } = getState();
    const userStudy = await getUserStudyFromProgram(program, prnOption);
    const consent = get(userStudy, '0.fields.consent');
    if (consent) {
      const resolvedConsent = resolveConsent(consent);
      dispatch(getContentfulConsentSuccess(resolvedConsent));
    } else {
      dispatch(getContentfulConsentSuccess(null));
    }
  } catch (err) {
    dispatch(
      getContentfulConsentFailure(
        getOr(
          err || 'an error has occurred fetching consent from Contentful',
          'response.data',
          err
        )
      )
    );
  }
};

export const getSHRStatus = accessToken => {
  return async dispatch => {
    try {
      dispatch(getStatusStart());
      const response = await request({
        url: 'shr/status',
        headers: {
          'Session-Key': accessToken
        }
      });
      const json = response.data;
      dispatch(getStatusSuccess(json));
    } catch (err) {
      dispatch(getStatusFailure(err));
    }
  };
};
export const getDoctorById = (accessToken, doctorId) => {
  return async dispatch => {
    try {
      dispatch(getDoctorByIdStart());

      const response = await request({
        url: `doctors/${doctorId}`,
        headers: {
          'Session-Key': accessToken
        }
      });

      dispatch(getDoctorByIdSuccess(response.data));
    } catch (err) {
      dispatch(getDoctorByIdFailure(err));
    }
  };
};
