import i18n from '../../i18n';

export default {
  defaultError: i18n.t('defaults.error'),
  pageTitle: i18n.t('profilePage.pageTitle'),
  pageDescription: i18n.t('profilePage.pageDescription'),
  detailsNameLabel: i18n.t('profilePage.detailsNameLabel'),
  detailsEmailLabel: i18n.t('profilePage.detailsEmailLabel'),
  detailsPhoneLabel: i18n.t('profilePage.detailsPhoneLabel'),
  detailsRoleLabel: i18n.t('profilePage.detailsRoleLabel'),
  detailsSitesLabel: i18n.t('profilePage.detailsSitesLabel'),
  detailsBlindingLabel: i18n.t('profilePage.detailsBlindingLabel'),
  editButtonLabel: i18n.t('profilePage.editButtonLabel'),
  changePasswordButtonLabel: i18n.t('profilePage.changePasswordButtonLabel'),
  lockupText: i18n.t('profilePage.lockupText'),
  formFields: {
    firstName: { label: i18n.t('profilePage.formFields.firstName.label') },
    lastName: { label: i18n.t('profilePage.formFields.lastName.label') },
    email: { label: i18n.t('profilePage.formFields.email.label') },
    phone: {
      label: i18n.t('profilePage.formFields.phone.label'),
      errorMessage: i18n.t('profilePage.formFields.phone.errorMessage')
    },
    roleName: { label: i18n.t('profilePage.formFields.roleName') },
    sites: { label: i18n.t('profilePage.formFields.sites') },
    blinding: { label: i18n.t('profilePage.formFields.blinding') }
  },
  editModalTitle: i18n.t('profilePage.editModalTitle'),
  editModalDescription: i18n.t('profilePage.editModalDescription'),
  editModalCancelButtonLabel: i18n.t('profilePage.editModalCancelButtonLabel'),
  editModalSubmitButtonLabel: i18n.t('profilePage.editModalSubmitButtonLabel'),
  reasonForChangeModalHeaderTitle: i18n.t(
    'profilePage.reasonForChangeModalHeaderTitle'
  ),
  reasonForChangeModalBodyText: i18n.t(
    'profilePage.reasonForChangeModalBodyText'
  ),
  reasonForChangeInputLabel: i18n.t('profilePage.reasonForChangeInputLabel'),
  reasonForChangeInputPlaceholder: i18n.t(
    'profilePage.reasonForChangeInputPlaceholder'
  ),
  reasonForChangeErrorMessage: i18n.t(
    'profilePage.reasonForChangeErrorMessage'
  ),
  reasonForChangeModalCancelButtonLabel: i18n.t(
    'profilePage.reasonForChangeModalCancelButtonLabel'
  ),
  reasonForChangeModalSubmitButtonLabel: i18n.t(
    'profilePage.reasonForChangeModalSubmitButtonLabel'
  ),
  changePasswordHeaderText: i18n.t('profilePage.changePasswordHeaderText'),
  oldPasswordFormField: {
    label: i18n.t('profilePage.oldPasswordFormField.label'),
    hideLabel: i18n.t('profilePage.oldPasswordFormField.hideLabel'),
    showLabel: i18n.t('profilePage.oldPasswordFormField.showLabel'),
    errorMessage: i18n.t('profilePage.oldPasswordFormField.errorMessage')
  },
  newPasswordFormField: {
    label: i18n.t('profilePage.newPasswordFormField.label'),
    hideLabel: i18n.t('profilePage.newPasswordFormField.hideLabel'),
    showLabel: i18n.t('profilePage.newPasswordFormField.showLabel')
  },
  changePasswordSuccessMessage: i18n.t(
    'profilePage.changePasswordSuccessMessage'
  ),
  changePasswordDefaultErrorMessage: i18n.t(
    'profilePage.changePasswordDefaultErrorMessage'
  ),
  changePasswordCancelButtonLabel: i18n.t(
    'profilePage.changePasswordCancelButtonLabel'
  ),
  changePasswordSubmitButtonLabel: i18n.t(
    'profilePage.changePasswordSubmitButtonLabel'
  ),
  changePasswordCloseButtonLabel: i18n.t(
    'profilePage.changePasswordCloseButtonLabel'
  )
};
