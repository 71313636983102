import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';

// Screens
import App from '../App';
import { LoginPage } from '../ts/components/pages/Login';
import { CreatePasswordPage } from '@ts/components/pages/CreatePassword';
import { ForgotPasswordPage } from '@ts/components/pages/ForgotPassword';
import { ResetPasswordPage } from '@ts/components/pages/ResetPassword';
import ConfirmToken from '../Components/ConfirmToken';
import MessagingPreferences from '../Components/MessagingPreferences.js';
import Dashboard from '../Components/Dashboard.js';
import Register from '../Components/RegisterNew/Register.js';
import PoliciesPage from '../Components/PoliciesPage';
import EmailConfirmationNeeded from '../Components/EmailConfirmationNeeded.js';
import Privacy from '../Components/Privacy.js';
import DashboardMyProvidersContainer from '../Components/containers/DashboardMyProvidersContainer';
import DashboardConsentContainer from '../Components/containers/DashboardConsentContainer';
import DashboardResourcesContainer from '../Components/containers/DashboardResourcesContainer';
import PrivateRoute from '../Components/PrivateRoute';
import DashboardMyRecordContainer from '../Components/containers/DashboardMyRecordContainer';
import DashboardProfileContainer from '../Components/containers/DashboardProfileContainer';

// Hooks
import { useAuth } from '../selectors/auth';
import LoadingSpinner from '../Components/shared/LoadingSpinner.js';

const LazyPortal = lazy(() => import('../ts/components/pages/Portal'));

export function Navigation({ scrollContainerRef }) {
  const { profileSession } = useAuth();

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <div className="routeContainer" data-testid="route-container">
        <Switch>
          {profileSession?.success && profileSession?.confirmed && (
            <Route
              exact
              path="/"
              render={props => (
                <DashboardResourcesContainer
                  {...props}
                  profileSession={profileSession}
                />
              )}
            />
          )}
          <Route
            exact
            path="/"
            render={props => (
              <App scrollContainerRef={scrollContainerRef} {...props} />
            )}
          />
          <Route exact path="/join/:diseaseCode/:programType" component={App} />
          <Route exact path="/join" component={App} />
          <Route exact path="/ascension" component={App} />
          <Route exact path="/ascension/:id" component={App} />
          <Route exact path="/login/:landing?" component={LoginPage} />
          <CompatRoute
            exact
            path="/create-password/:token"
            component={CreatePasswordPage}
          />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Route
            exact
            path="/reset-password/:token"
            component={ResetPasswordPage}
          />
          <Route exact path="/confirm/:token" component={ConfirmToken} />
          <Route
            path="/communication/:token"
            component={MessagingPreferences}
          />
          <Route exact path="/privacy">
            <Privacy type="privacy" />
          </Route>
          <Route exact path="/terms">
            <Privacy type="terms" />
          </Route>
          <Route
            exact
            path="/policies/:diseaseCode/:programType"
            component={PoliciesPage}
          />
          <Route
            exact
            path="/register/:diseaseCode/:programType"
            component={Register}
          />
          <PrivateRoute
            exact
            path="/confirm_email"
            renderComponent={EmailConfirmationNeeded}
          />
          <PrivateRoute
            exact
            path="/home"
            renderComponent={DashboardResourcesContainer}
          />
          <PrivateRoute
            exact
            path="/home/shr"
            renderComponent={DashboardMyRecordContainer}
          />
          <PrivateRoute
            exact
            path="/home/profile"
            renderComponent={DashboardProfileContainer}
          />
          <PrivateRoute
            exact
            path="/home/authorizations"
            renderComponent={DashboardConsentContainer}
          />
          <PrivateRoute
            exact
            path="/home/authorizations/:type/:id"
            renderComponent={DashboardConsentContainer}
          />
          <PrivateRoute path="/home/:page/" renderComponent={Dashboard} />

          <PrivateRoute path="/home/shr/record" renderComponent={Dashboard} />
          <PrivateRoute path="/home/:page/:id" renderComponent={Dashboard} />
          <PrivateRoute
            path="/home/shr/providers"
            renderComponent={DashboardMyProvidersContainer}
            exact
          />
          <PrivateRoute path="/portal" renderComponent={LazyPortal} />
          <Route render={props => <App is404 {...props} />} />
        </Switch>
      </div>
    </Suspense>
  );
}

Navigation.propTypes = {
  scrollContainerRef: PropTypes.any
};

export default Navigation;
