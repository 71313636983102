import i18n from '../i18n';
import { SET_USER_LOCALE } from '../constants/actionTypes';
import { client, setUserLocale as _setUserLocale } from '../utils/cms';
import { createFetchActions } from './utils';

export const setUserLocaleAction = locale => ({
  type: SET_USER_LOCALE,
  payload: locale
});

export const setUserLocale = locale => {
  return async dispatch => {
    if (locale) {
      _setUserLocale(locale);
    }
    await i18n.changeLanguage(locale);
    dispatch(setUserLocaleAction(locale));
  };
};

export const {
  types: cmsLocalesTypes,
  actions: cmsLocalesActions
} = createFetchActions('CMS_LOCALES');

export function fetchLocales() {
  return async dispatch => {
    dispatch(cmsLocalesActions.START());
    try {
      const items = await client.getLocales();
      dispatch(cmsLocalesActions.SUCCESS(items));
    } catch (error) {
      dispatch(cmsLocalesActions.FAILURE(error));
    }
  };
}
