import React from 'react';
import { PrimaryButton } from '../atoms/buttons';
import {
  DialogBody,
  DialogFooter,
  DialogFooterActions,
  DialogHeader
} from '../atoms/dialog';
import Dialog from './Dialog';
import { Body1Div, H3 } from '../atoms/typography';
import { CloseIcon } from '../atoms/images';

type MessageModalProps = {
  title: string;
  message: string;
  submitBtnLabel?: string;
  onClose: () => void;
  visible: boolean;
};

const MessageModal = ({
  title,
  message,
  submitBtnLabel,
  onClose,
  visible
}: MessageModalProps) => {
  return (
    <Dialog isOpen={visible} onClose={onClose} width={400}>
      <DialogHeader>
        <H3>{title}</H3>
        <CloseIcon onClick={onClose} data-testid={`close-msg-modal-btn`} />
      </DialogHeader>
      <DialogBody $minHeight={84}>
        <Body1Div>{message}</Body1Div>
      </DialogBody>
      <DialogFooter $mode="flex-end">
        <DialogFooterActions>
          <PrimaryButton
            type="button"
            onClick={onClose}
            data-testid={`msg-modal-submit-btn`}
          >
            {submitBtnLabel || 'Close'}
          </PrimaryButton>
        </DialogFooterActions>
      </DialogFooter>
    </Dialog>
  );
};

export default MessageModal;
