import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { DialogElement, DialogWrapper } from '../atoms/dialog';

type DialogProps = PropsWithChildren & {
  isOpen?: boolean;
  onClose?: () => void;
  className?: string;
  width?: number;
  'data-testid'?: string;
};
function Dialog({
  children,
  className,
  isOpen,
  onClose,
  width,
  'data-testid': testId
}: Readonly<DialogProps>) {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal?.();
    } else {
      dialogRef.current?.close?.();
    }
  }, [isOpen]);

  return (
    <DialogElement
      ref={dialogRef}
      onCancel={onClose}
      className={className}
      data-testid={testId}
    >
      <DialogWrapper $width={width}>{children}</DialogWrapper>
    </DialogElement>
  );
}

export default Dialog;
