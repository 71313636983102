import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { cleanLocaleName } from '@src/Components/LocalePicker/utils';
import {
  Icon,
  LocaleOption,
  OptionsContainer,
  SelectorContainer
} from '../atoms/localeSelector';
import { Locale } from '@ts/models';
import useLocale from '../../../hooks/useLocale';

const LocaleSelector = () => {
  const { locales, userLocale, selectUserLocale } = useLocale();
  const [open, setOpen] = useState(false);

  const handleLocaleSelect = useCallback(
    (locale: Locale) => selectUserLocale(locale.code),
    [selectUserLocale]
  );

  const dropdownEl = useRef<HTMLDivElement>(null);

  const toggleOpen = useCallback(() => setOpen(prev => !prev), []);

  const selectedLocale = useMemo(() => {
    return (
      locales.find((locale: Locale) => locale.code === userLocale) || {
        code: 'en-US',
        name: 'English'
      }
    );
  }, [locales, userLocale]);

  const handleMouseUp = useCallback((event: Event) => {
    const path = event.composedPath();
    if (!path.find(element => element === dropdownEl.current)) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseUp]);

  return (
    <SelectorContainer
      onClick={toggleOpen}
      $open={open}
      data-testid="locale-selector-container"
      ref={dropdownEl}
    >
      <Icon $url={'/portal/planet.svg'} />
      {cleanLocaleName(selectedLocale, true)}
      <Icon $url={'/portal/chevron-down.svg'} $open={open} />
      {open && (
        <OptionsContainer data-testid="locale-selector-options-container">
          {locales.map((locale: Locale) => {
            const selected = locale.code === userLocale;
            return (
              <LocaleOption
                key={locale.code}
                data-testid={`locale-option-${locale.code}`}
                onClick={() => handleLocaleSelect(locale)}
                $selected={selected}
              >
                {/* {cleanLocaleName(locale, true)} */}
                {locale.name}
                {selected && <Icon $url={'/portal/done.svg'} $size={16} />}
              </LocaleOption>
            );
          })}
        </OptionsContainer>
      )}
    </SelectorContainer>
  );
};
export default LocaleSelector;
